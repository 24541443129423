import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useContext, useEffect } from "react";
import { SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_FEATURED_EVENT_PRODUCTS } from "@catchoftheday/analytics";
import { SHOP_PAGE_PRODUCT_RECOMMENDER_LIMIT } from "../../../consts";
import { OnePassHeroDealsContext } from "../../../contexts/OnePassHeroDealsContext";
import { useFeaturedEventProductsLazyQuery } from "../../../generated/graphql";
import { getEventTitles } from "../../../utilities";
import { ShopProductsCarouselZone } from "../../layouts/ShopProductsCarouselLayout/ShopProductsCarouselLayout";
import { FeaturedEventSectionHeading } from "../../pure/FeaturedEventSectionHeading";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopFeaturedProductsContainer = function ShopFeaturedProductsContainer(_ref) {
  var _entry$event2;

  var ProductsCarouselComponent = _ref.ProductsCarouselComponent;

  var _useContext = useContext(OnePassHeroDealsContext),
      heroDealsData = _useContext.data;

  var entries = heroDealsData.entries,
      isSecondEntryCarousel = heroDealsData.isSecondEntryCarousel;

  var _useFeaturedEventProd = useFeaturedEventProductsLazyQuery({
    ssr: false
  }),
      _useFeaturedEventProd2 = _slicedToArray(_useFeaturedEventProd, 2),
      getProducts = _useFeaturedEventProd2[0],
      productsData = _useFeaturedEventProd2[1].data;

  var _ref2 = productsData || {},
      featuredProductsByEventId = _ref2.featuredProductsByEventId;

  var _ref3 = featuredProductsByEventId || {},
      products = _ref3.products;

  var entry = entries[1];
  useEffect(function () {
    var _entry$event;

    if (isSecondEntryCarousel && entry !== null && entry !== void 0 && (_entry$event = entry.event) !== null && _entry$event !== void 0 && _entry$event.id) {
      getProducts({
        variables: {
          eventId: entry.event.id,
          limit: SHOP_PAGE_PRODUCT_RECOMMENDER_LIMIT
        }
      });
    }
  }, [entry === null || entry === void 0 ? void 0 : (_entry$event2 = entry.event) === null || _entry$event2 === void 0 ? void 0 : _entry$event2.id, getProducts, isSecondEntryCarousel]);

  if (!isSecondEntryCarousel || !(products !== null && products !== void 0 && products.length) || !(entry !== null && entry !== void 0 && entry.event)) {
    return null;
  }

  var _getEventTitles = getEventTitles(entry.event),
      title = _getEventTitles.title,
      subtitle = _getEventTitles.subtitle;

  return ___EmotionJSX(ShopProductsCarouselZone, {
    sectionHeading: ___EmotionJSX(FeaturedEventSectionHeading, {
      textProps: {
        color: "onePass.primary"
      }
    }),
    title: title,
    subtitle: subtitle,
    viewAllUrl: entry.event.url
  }, ___EmotionJSX(ProductsCarouselComponent, {
    products: products,
    sourceId: entry.event.id,
    sourceType: SOURCE_TYPE_SHOP_PAGE_RECOMMENDER_FEATURED_EVENT_PRODUCTS
  }));
};