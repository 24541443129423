import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _excluded = ["url", "hasChildren", "shopAll", "highlight", "itemPath", "children", "onClick"];

var _templateObject, _templateObject2;

import React, { useCallback, useContext, useMemo } from "react";
import { Flex, IconDownArrow, Link, ListItem } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { trackMenuClick, trackMenuImpression } from "../../utilities/analytics";
import { MobileMenuContext } from "../MobileMenuContext";
import { getAbsoluteUrl } from "../utilities";
import { jsx as ___EmotionJSX } from "@emotion/react";
var ExpanderChevron = styled(IconDownArrow)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  position: absolute;\n  transform: rotate(270deg);\n  right: 18px;\n  width: 10px;\n  height: 5px;\n  top: calc(50% - 2.5px);\n"])));
var ItemWrapper = styled(Flex)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  position: relative;\n  margin: 0px 15px;\n  height: 48px;\n  border-bottom: 0.5px solid #d3d3d3;\n\n  ", "\n\n  ", "\n\n  li,\n  a {\n    height: 100%;\n    width: 100%;\n    display: flex;\n    align-items: center;\n  }\n\n  a,\n  a:hover,\n  a:focus {\n    color: #222222;\n    text-decoration: none;\n    outline: none;\n\n    svg {\n      color: #222222;\n    }\n  }\n"])), function (props) {
  return props.highlight && "\n    padding: 0px 17px;\n    margin: 0px;\n    margin-top: -1px;\n    height: 54px;\n    border-top: ".concat(props.borderTop || "4px solid", ";\n    border-bottom: ").concat(props.borderBottom || "4px solid", ";\n    border-color: ").concat(themeGet("colors.ui.borderColor")(props), ";\n  ");
}, function (props) {
  return !props.highlight && "    &:last-child {\n    border-bottom: none;\n  }";
});
export var MenuItem = function MenuItem(_ref) {
  var url = _ref.url,
      hasChildren = _ref.hasChildren,
      shopAll = _ref.shopAll,
      highlight = _ref.highlight,
      itemPath = _ref.itemPath,
      children = _ref.children,
      onClick = _ref.onClick,
      props = _objectWithoutProperties(_ref, _excluded);

  var _useContext = useContext(MobileMenuContext),
      sourceType = _useContext.sourceType;

  var handleClick = useCallback(function () {
    if (typeof onClick === "function" && hasChildren) {
      onClick();
    }

    if (!itemPath) {
      return;
    }

    if (!hasChildren && url) {
      trackMenuClick({
        name: itemPath[itemPath.length - 1].name,
        url: getAbsoluteUrl(url),
        item_path: itemPath,
        source_type: sourceType
      }); // Only track menu impressions for level 1
    } else if (itemPath.length === 1) {
      trackMenuImpression({
        name: itemPath[itemPath.length - 1].name,
        item_path: itemPath,
        source_type: sourceType
      });
    }
  }, [onClick, hasChildren, itemPath, url, sourceType]);
  var renderListItem = useMemo(function () {
    return ___EmotionJSX(ListItem, {
      mb: "0px",
      lineHeight: "21px",
      fontSize: "sm",
      fontWeight: shopAll ? "medium" : "light",
      color: shopAll ? "secondary" : undefined,
      onClick: handleClick
    }, children, !shopAll && hasChildren && ___EmotionJSX(ExpanderChevron, null));
  }, [handleClick, hasChildren, shopAll, children]);
  var renderLinkItem = useMemo(function () {
    return ___EmotionJSX(Link, {
      href: url
    }, renderListItem);
  }, [renderListItem, url]);
  return ___EmotionJSX(ItemWrapper, _extends({
    highlight: highlight
  }, props), url && !hasChildren ? renderLinkItem : renderListItem);
};