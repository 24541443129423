import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject;

import React from "react";
import { Hide } from "@catchoftheday/cg-components";
import { minusOnePx, styled, theme, themeGet, useMedia } from "@catchoftheday/theme";
import { Container } from "../../components/Container";
import { MobileMenuContext } from "./MobileNav/MobileMenuContext";
import { orderPinToTopMenuItems } from "./utilities/orderMenuItems";
import { DesktopNav } from "./DesktopNav";
import { MobileNav } from "./MobileNav";
import { jsx as ___EmotionJSX } from "@emotion/react";
var DesktopContainer = styled(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  &::after {\n    content: \"\";\n    position: absolute;\n    bottom: 0px;\n    z-index: 0;\n    left: 0px;\n    width: 100%;\n    height: 2px;\n    background-color: ", ";\n  }\n\n  @media (max-width: ", ") and (min-width: ", ") {\n    padding-left: 0;\n    padding-right: 0;\n    position: relative;\n    overflow: hidden;\n  }\n"])), function (props) {
  return themeGet("colors.grey")(props);
}, function (props) {
  return minusOnePx(themeGet("breakpoints.lg")(props));
}, themeGet("breakpoints.md"));
export var MegaMenu = function MegaMenu(_ref) {
  var _ref$navData = _ref.navData,
      items = _ref$navData.items,
      pinToTopItems = _ref$navData.pinToTopItems,
      accountMenu = _ref.accountMenu,
      isLoggedIn = _ref.isLoggedIn,
      cartItemsCount = _ref.cartItemsCount,
      wishlistItemsCount = _ref.wishlistItemsCount,
      sourceType = _ref.sourceType,
      _ref$isMinimalHeaderE = _ref.isMinimalHeaderEnabled,
      isMinimalHeaderEnabled = _ref$isMinimalHeaderE === void 0 ? false : _ref$isMinimalHeaderE;
  var isMobile = useMedia(["(max-width: ".concat(minusOnePx(theme.breakpoints.md, true), ")")], [true]);
  var orderedPinToTopItems = orderPinToTopMenuItems(pinToTopItems);
  if (isMinimalHeaderEnabled && !isMobile) return null;

  if (isMobile) {
    return ___EmotionJSX(MobileMenuContext.Provider, {
      value: {
        items: items,
        sourceType: sourceType,
        pinToTopItems: orderedPinToTopItems,
        accountMenu: accountMenu,
        isLoggedIn: isLoggedIn,
        cartItemsCount: cartItemsCount,
        wishlistItemsCount: wishlistItemsCount
      }
    }, ___EmotionJSX(MobileNav, {
      isMinimalHeaderEnabled: isMinimalHeaderEnabled
    }));
  }

  return ___EmotionJSX(Hide, {
    xs: true,
    sm: true
  }, ___EmotionJSX(DesktopContainer, {
    position: "relative",
    backgroundColor: "ui.backgroundColor"
  }, ___EmotionJSX(DesktopNav, {
    items: items,
    pinToTopItems: orderedPinToTopItems,
    sourceType: sourceType
  })));
};