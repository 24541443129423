import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _taggedTemplateLiteral from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var _excluded = ["mobileFacetsOpen"];

var _templateObject, _templateObject2;

import React from "react";
import { Box, Container, Flex, IconOnePass, TextButton } from "@catchoftheday/cg-components";
import { styled, themeGet } from "@catchoftheday/theme";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var PageLayoutZone = function PageLayoutZone(props) {
  return ___EmotionJSX(Container, _extends({
    px: 3,
    flexDirection: "column",
    position: "relative"
  }, props));
};
export var HeadingZone = function HeadingZone(props) {
  return ___EmotionJSX(Flex, _extends({
    alignItems: "center",
    justifyContent: "space-between"
  }, props));
};
export var BannerZone = function BannerZone(props) {
  return ___EmotionJSX(Box, _extends({
    display: "grid",
    gridAutoColumns: "1fr",
    justifyContent: "center",
    mb: 4
  }, props));
};
var FacetModal = styled(Box)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  transition: opacity 0.3s, top 0.3s ease-in-out;\n"])));
export var FacetsZone = function FacetsZone(_ref) {
  var mobileFacetsOpen = _ref.mobileFacetsOpen,
      props = _objectWithoutProperties(_ref, _excluded);

  return ___EmotionJSX(FacetModal, _extends({
    opacity: {
      xs: mobileFacetsOpen ? "1" : "0",
      md: "1"
    },
    pointerEvents: {
      xs: mobileFacetsOpen ? "auto" : "none",
      md: "auto"
    },
    position: {
      xs: "fixed",
      md: "relative"
    },
    zIndex: {
      xs: 50,
      md: "auto"
    },
    overflow: {
      xs: "auto",
      md: "visible"
    },
    top: {
      xs: mobileFacetsOpen ? "0px" : "10px",
      md: "auto"
    },
    right: {
      xs: "0px",
      md: "auto"
    },
    left: {
      xs: "0px",
      md: "auto"
    },
    bottom: {
      xs: "0px",
      md: "auto"
    },
    backgroundColor: "ui.backgroundColor"
  }, props), ___EmotionJSX(Box, {
    width: {
      xs: "100%",
      md: "250px",
      lg: "330px"
    }
  }, props.children));
};
export var ProductsZone = function ProductsZone(props) {
  return ___EmotionJSX(Box, _extends({
    flexGrow: 1,
    pl: {
      _: 2,
      md: 6
    },
    pr: {
      _: 2,
      md: 0
    }
  }, props));
};
export var ResultsZone = function ResultsZone(props) {
  return ___EmotionJSX(Flex, props);
};
export var PaginationZone = function PaginationZone(props) {
  return ___EmotionJSX(Flex, _extends({
    justifyContent: "center",
    mb: 12,
    mt: 6
  }, props));
};
export var MobileStickyHeader = function MobileStickyHeader(props) {
  return ___EmotionJSX(Flex, _extends({
    display: {
      xs: "flex",
      md: "none"
    },
    justifyContent: "space-between",
    mb: 6,
    width: {
      xs: "100%",
      md: "250px"
    },
    height: {
      xs: "40px",
      md: "auto"
    },
    alignItems: "center",
    position: {
      xs: "sticky",
      md: "absolute"
    },
    top: {
      xs: "70px",
      md: "30px"
    },
    right: {
      xs: "auto",
      md: "0px"
    },
    background: {
      xs: "white",
      md: "none"
    },
    style: {
      zIndex: 11
    },
    boxShadow: {
      xs: "0 0 5px 5px rgba(0, 0, 0, 0.15)",
      md: "none"
    }
  }, props));
};
export var SortOptionZone = function SortOptionZone(props) {
  return ___EmotionJSX(Flex, _extends({
    width: {
      xs: "50%",
      md: "auto"
    },
    justifyContent: "center",
    display: {
      xs: "flex",
      md: "block"
    }
  }, props));
};
export var MobileFacetHeaderZone = function MobileFacetHeaderZone(props) {
  return ___EmotionJSX(Flex, _extends({
    alignItems: "center",
    justifyContent: "space-between",
    height: "50px",
    backgroundColor: "secondary",
    boxShadow: "2px 2px 5px 0 rgba(0, 0, 0, 0.5)",
    display: {
      xs: "flex",
      md: "none"
    },
    position: "sticky",
    top: "0",
    style: {
      zIndex: 11
    }
  }, props));
};
export var MobileFacetFooterZone = function MobileFacetFooterZone(props) {
  return ___EmotionJSX(Flex, _extends({
    position: "sticky",
    zIndex: 1,
    right: 0,
    bottom: 0,
    left: 0,
    justifyContent: "center",
    height: "68px",
    padding: 3,
    backgroundColor: "white",
    boxShadow: "2px 2px 10px 0 rgba(0, 0, 0, 0.5)",
    display: {
      xs: "flex",
      md: "none"
    }
  }, props));
};
export var NoResultsZone = function NoResultsZone(props) {
  return ___EmotionJSX(PageLayoutZone, null, ___EmotionJSX(Box, _extends({
    my: 5,
    width: "100%"
  }, props)));
};
export var OnePassIcon = function OnePassIcon() {
  return ___EmotionJSX(IconOnePass, {
    "aria-label": "OnePass",
    width: "60px",
    height: "14px",
    mr: "4px",
    mt: "-2px",
    color: "onePass.primary"
  });
};
export var SizeItemButton = styled(TextButton)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  border: solid 1px ", ";\n  &:active,\n  &:hover {\n    border-color: ", ";\n    text-decoration: none;\n  }\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n  white-space: nowrap;\n  padding: 4px;\n  border-radius: 4px;\n  min-width: 46px;\n  ", "\n"])), themeGet("colors.ui.borderColor"), themeGet("colors.secondary"), themeGet("fontSizes.sm"), themeGet("fontWeights.light"), themeGet("colors.text"), function (props) {
  return props.selected && "\n  background-color: ".concat(themeGet("colors.secondary")(props), ";\n  border-color: ").concat(themeGet("colors.secondary")(props), ";\n  color: white;\n");
});
export var NoProductsZone = function NoProductsZone(props) {
  return ___EmotionJSX(Box, _extends({
    width: "100%",
    background: "white"
  }, props, {
    p: 4
  }));
};
export var MobileHeaderZone = function MobileHeaderZone(props) {
  return ___EmotionJSX(Box, _extends({
    pl: 2,
    mb: 5,
    display: {
      _: "block",
      md: "none"
    }
  }, props));
};
export var DesktopHeaderZone = function DesktopHeaderZone(props) {
  return ___EmotionJSX(Box, _extends({
    mt: 8,
    mb: 5,
    display: {
      _: "none",
      md: "block"
    }
  }, props));
};
export var RecentTermZone = function RecentTermZone(props) {
  return ___EmotionJSX(Box, _extends({
    flex: "1 0 auto",
    width: "100%"
  }, props));
};
export var RelatedSearchesZone = function RelatedSearchesZone(props) {
  return ___EmotionJSX(Flex, _extends({
    mt: 2,
    flexWrap: "wrap",
    alignItems: "center",
    lineHeight: "1.25rem",
    maxHeight: {
      xs: "40px",
      md: "48px"
    },
    overflow: "hidden"
  }, props));
};