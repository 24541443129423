import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React, { useCallback, useContext } from "react";
import useWebsocket from "react-use-websocket";
import { AppExperimentContext } from "@catchoftheday/analytics";
import { Carousel, ContentPlaceHolder } from "@catchoftheday/cg-components";
import { CustomerContext } from "@catchoftheday/customer";
import { getEnvConfig } from "@catchoftheday/env-config";
import { useSocialUrgencyMessage } from "@catchoftheday/state";
import { getSalesChannel } from "@catchoftheday/utilities";
import { OnePassHeroDealsContext } from "../../../contexts/OnePassHeroDealsContext";
import { isActiveOnePassMember } from "../../../utilities";
import { ShopHeroBannerContentZone, ShopHeroBannerZone } from "../../layouts/ShopHeroBannerLayout/ShopHeroBannerLayout";
import { FeaturedEventSectionHeading } from "../../pure/FeaturedEventSectionHeading";
import { EventBanner } from "./EventBanner";
import { jsx as ___EmotionJSX } from "@emotion/react";
export var ShopHeroBannerContainer = function ShopHeroBannerContainer(_ref) {
  var host = _ref.host;

  // Hero deal experiment
  var _useContext = useContext(AppExperimentContext),
      getExperiments = _useContext.getExperiments;

  var _getExperiments = getExperiments(["onepass_hero_deal_revamp_nov_22"]),
      _getExperiments$exper = _slicedToArray(_getExperiments.experiments, 1),
      heroDealExperiment = _getExperiments$exper[0],
      experimentLoading = _getExperiments.loading; // Hero deals context


  var _useContext2 = useContext(OnePassHeroDealsContext),
      heroDealsData = _useContext2.data,
      heroDealsLoading = _useContext2.loading;

  var eventEntries = heroDealsData.eventEntries,
      isSecondEntryCarousel = heroDealsData.isSecondEntryCarousel; // Customer context

  var _useContext3 = useContext(CustomerContext),
      customerData = _useContext3.data;

  var isActiveMember = isActiveOnePassMember(customerData); // Social urgency

  var _useWebsocket = useWebsocket(getEnvConfig().SOCIAL_URGENCY_WEBSOCKET_URL),
      sendJsonMessage = _useWebsocket.sendJsonMessage,
      lastJsonMessage = _useWebsocket.lastJsonMessage;

  var lastSocialUrgencyMessage = useSocialUrgencyMessage({
    sendMessage: sendJsonMessage,
    lastMessage: lastJsonMessage,
    topicInputs: eventEntries && eventEntries.length > 0 ? eventEntries.map(function (entry) {
      var _entry$event;

      return {
        id: (_entry$event = entry.event) === null || _entry$event === void 0 ? void 0 : _entry$event.id,
        context: "event"
      };
    }) : [],
    salesChannelCode: getSalesChannel(host)
  });
  /**
   * Function to filter the latest message coming in from websocket
   * by comparing it with the given eventId so that we only pass the
   * appropriate stats to the EventBanner component.
   */

  var getTopicStats = useCallback(function (eventId) {
    return lastSocialUrgencyMessage.id === eventId ? lastSocialUrgencyMessage.stats : null;
  }, [lastSocialUrgencyMessage.id, lastSocialUrgencyMessage.stats]);
  var isReady = !experimentLoading && !heroDealsLoading;
  var isNewHeroDeal = isReady && (heroDealExperiment === null || heroDealExperiment === void 0 ? void 0 : heroDealExperiment.bucket) === 1;
  var renderBanner = useCallback(function (entry) {
    if (entry.event) {
      return ___EmotionJSX(EventBanner, {
        event: entry.event,
        socialUrgencyStats: getTopicStats(entry.event.id),
        endDate: entry.endDate,
        isNewHeroDeal: isNewHeroDeal,
        isActiveMember: isActiveMember,
        isOnePassExclusive: entry.onePassExclusive
      });
    }

    return null;
  }, [getTopicStats, isActiveMember, isNewHeroDeal]);

  if (!isReady) {
    return ___EmotionJSX(ShopHeroBannerZone, {
      role: "progressbar"
    }, ___EmotionJSX(ContentPlaceHolder, {
      speed: 2,
      width: "100%",
      height: "50vh"
    }, ___EmotionJSX("rect", {
      width: "100%",
      height: "50vh"
    })));
  }

  if (isReady && eventEntries && eventEntries.length > 0) {
    var heroEntry = eventEntries[0];
    return ___EmotionJSX(ShopHeroBannerZone, {
      padding: !isNewHeroDeal ? "0 !important" : undefined
    }, ___EmotionJSX(ShopHeroBannerContentZone, null, isNewHeroDeal ? null : ___EmotionJSX(FeaturedEventSectionHeading, null), !isSecondEntryCarousel ? ___EmotionJSX(Carousel, {
      slides: eventEntries.map(function (entry) {
        return entry.event && renderBanner(entry);
      }),
      showNavButtons: isNewHeroDeal ? false : true,
      showBullets: isNewHeroDeal ? true : false,
      showNavButtonsOnHover: true,
      infinite: true,
      interval: isNewHeroDeal ? 20000 : 3000,
      autoPlay: true
    }) : heroEntry.event ? renderBanner(heroEntry) : null));
  }

  return null;
};